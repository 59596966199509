@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Mate+SC&family=Pacifico&family=Playfair+Display&family=Staatliches&family=Viaoda+Libre&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap");

class dancing_script {
  font-family: "Dancing Script", cursive;
}
class mate_sc {
  font-family: "Mate SC", serif;
}
class pacifico {
  font-family: "Pacifico", cursive;
}
class playfair_display {
  font-family: "Playfair Display", serif;
}
class staatliches {
  font-family: "Staatliches", cursive;
}
class viaoda_libre {
  font-family: "Viaoda Libre", cursive;
}
